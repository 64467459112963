import logo from './logo.svg';
import logoLinked from './LogoLinked.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logoLinked} className="App-logo" alt="logo" />
                <p>
                    Em Breve!
                    Aguarde novidades!
                </p>
            </header>
        </div>
    );
}

export default App;
